@use 'sass:math';
@import './variables';

@function remy($pxsize) {
  @return math.div($pxsize, 16) + rem;
}

:root {
  --fill-grey-lighter: #eff1f0;
  --fill-grey-dark: #82919b;
  --fill-white: #ffffff;
  --fill-grey-light: #c6cdcf;
  --fill-grey-darker: #444e53;
  --fill-black: #000000;
  --fill-pink: #ffdff3;
  --fill-red: #cd3a3a;
  --fill-blue: #005abb;
  --fill-green: #4cc947;
  --fill-light-blue: #eef6ff;
  --fill-yellow: #efeb85;
  --status-old-100: #d0d0ce;
}

.map-container {
  position: relative;
  flex: 1;
}

.content,
.container,
.main-content,
.info-map-content,
#root,
body {
  display: flex;
  flex: 1;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

html,
body {
  min-height: 100vh;
  margin: 0;
  padding: 0;
}

.sidebar {
  display: flex;
  flex-direction: column;
  min-width: remy(100);
  a {
    display: block;
    padding: remy(10);
    text-align: center;
    font-weight: bold;
    color: $blue;
    text-decoration: none;
    margin: remy(10);

    &.current {
      color: $black;
    }
  }
}

.side-dialog {
  width: 20vw;
  min-width: 300px;
}

.main-content {
  flex-direction: column;
  background-color: #f3f3f3;
}

.user-nav {
  min-height: remy(50);
}

.info-map-content {
  flex-direction: row;
  background-color: #f3f3f3;
}

.Main {
  flex-direction: column;
  display: flex;
  flex: 1;
  background-color: #eff1f0;
  margin-left: 75px;
}

// Hide info window close button
.gm-style-iw-d + button {
  display: none; /* <-- this will generally work on the fly. */
  visibility: hidden; /* this 2 lines below are just for hard hiding. :) */
  opacity: 0;
}
.gm-style .gm-style-iw-t::after {
  display: none;
}
