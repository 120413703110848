/*$logoImgWidth: remy(70);*/
/*$scrollbarWidth: remy(5);*/
/* custom */
/* custom navmenu */
/* end */
/* end */
:root {
  --fill-grey-lighter: #eff1f0;
  --fill-grey-dark: #82919b;
  --fill-white: #ffffff;
  --fill-grey-light: #c6cdcf;
  --fill-grey-darker: #444e53;
  --fill-black: #000000;
  --fill-pink: #ffdff3;
  --fill-red: #cd3a3a;
  --fill-blue: #005abb;
  --fill-green: #4cc947;
  --fill-light-blue: #eef6ff;
  --fill-yellow: #efeb85;
  --status-old-100: #d0d0ce;
}

.map-container {
  position: relative;
  flex: 1;
}

.content,
.container,
.main-content,
.info-map-content,
#root,
body {
  display: flex;
  flex: 1;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

html,
body {
  min-height: 100vh;
  margin: 0;
  padding: 0;
}

.sidebar {
  display: flex;
  flex-direction: column;
  min-width: 6.25rem;
}
.sidebar a {
  display: block;
  padding: 0.625rem;
  text-align: center;
  font-weight: bold;
  color: #005ABB;
  text-decoration: none;
  margin: 0.625rem;
}
.sidebar a.current {
  color: #000;
}

.side-dialog {
  width: 20vw;
  min-width: 300px;
}

.main-content {
  flex-direction: column;
  background-color: #f3f3f3;
}

.user-nav {
  min-height: 3.125rem;
}

.info-map-content {
  flex-direction: row;
  background-color: #f3f3f3;
}

.Main {
  flex-direction: column;
  display: flex;
  flex: 1;
  background-color: #eff1f0;
  margin-left: 75px;
}

.gm-style-iw-d + button {
  display: none;
  /* <-- this will generally work on the fly. */
  visibility: hidden;
  /* this 2 lines below are just for hard hiding. :) */
  opacity: 0;
}

.gm-style .gm-style-iw-t::after {
  display: none;
}
:root {
  --fill-grey-lighter: #eff1f0;
  --fill-grey-dark: #82919b;
  --fill-white: #ffffff;
  --fill-grey-light: #c6cdcf;
  --fill-grey-darker: #444e53;
  --fill-black: #000000;
  --fill-pink: #ffdff3;
  --fill-red: #cd3a3a;
  --fill-blue: #005abb;
  --fill-green: #4cc947;
  --fill-light-blue: #eef6ff;
  --fill-yellow: #efeb85;
  --status-old-100: #d0d0ce;
}

.Map-buttons {
  width: 160px;
  height: 45px;
  margin: 0 5px 0 0 !important;
  padding: 14px 61px 13px 62px;
  border-radius: 22px !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border: solid 2px #c6cdcf !important;
  background-color: white !important;
  text-transform: none !important;
}

.Map-buttons-sel {
  width: 160px;
  height: 45px;
  margin: 0 5px 0 0 !important;
  border-radius: 22px !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border: solid 2px var(--fill-blue) !important;
  background-color: var(--fill-light-blue) !important;
  text-transform: none !important;
}

.Map-Road-select,
.Map-Type-select {
  height: 45px;
  margin: 0 5px 0 0 !important;
  border-radius: 22px !important;
  box-shadow: none;
  border: solid 2px var(--fill-blue) !important;
  background-color: var(--fill-light-blue) !important;
  text-transform: none !important;
}

.Map-Road-select.MuiInput-underline:hover:not(.Mui-disabled)::before, .Map-Road-select.MuiInput-underline::before, .Map-Road-select.MuiInput-underline::after,
.Map-Type-select.MuiInput-underline:hover:not(.Mui-disabled)::before,
.Map-Type-select.MuiInput-underline::before,
.Map-Type-select.MuiInput-underline::after {
  content: "";
  transition: none;
  border-bottom: none;
}

.Map-Road-select .MuiSelect-select,
.Map-Type-select .MuiSelect-select {
  margin: 0 20px;
}

.Map-Road-select .MuiSelect-select:focus,
.Map-Type-select .MuiSelect-select:focus {
  background-color: transparent;
}

.Map-Road-select.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline, .Map-Road-select.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline, .Map-Road-select.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
.Map-Type-select.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
.Map-Type-select.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline,
.Map-Type-select.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border: none;
}

.box-selected {
  height: 70px !important;
  padding: 15px 29px 14px !important;
  box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.5) !important;
  background-color: #fff !important;
  flex-direction: column;
  justify-content: center !important;
}

.box-unselected {
  width: 97px;
  height: 66px;
  padding: 15px 34px 10px 35px;
  flex-direction: column;
}

.label-sel {
  height: 18px;
  margin: 23px 0 0;
  font-family: Muli;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center !important;
  color: #005abb;
}

.label-uns {
  height: 18px;
  margin: 23px 0 0;
  font-family: Muli;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #82919b;
}

.Bitmap {
  background-image: url("logo@2x.4d4bc682.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 0px;
  margin: 0px;
  width: 70px;
  height: 70px;
}

.leaflet-marker-icon {
  -webkit-filter: drop-shadow(2px 0 2px rgba(0, 0, 0, 0.5));
  filter: drop-shadow(2px 0 2px rgba(0, 0, 0, 0.5));
}

path.leaflet-interactive,
.map-element-circle,
.map-element-polyline,
.denm-marker-icon {
  -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.5));
  filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.5));
}

.marker-event-terminated {
  filter: grayscale(100%);
}

.marker-event-selected {
  -webkit-filter: drop-shadow(1px 1px 10px #FFFF33) drop-shadow(-1px -1px 10px #FFFF33) drop-shadow(1px -1px 20px #FFFF33);
  filter: drop-shadow(1px 1px 10px #FFFF33) drop-shadow(-1px -1px 10px #FFFF33) drop-shadow(1px -1px 20px #FFFF33);
  z-index: 1000 !important;
}

.marker-event-terminated.marker-event-selected {
  -webkit-filter: grayscale(100%) drop-shadow(1px 1px 10px #FFFF33) drop-shadow(-1px -1px 10px #FFFF33) drop-shadow(1px -1px 20px #FFFF33);
  filter: grayscale(100%) drop-shadow(1px 1px 10px #FFFF33) drop-shadow(-1px -1px 10px #FFFF33) drop-shadow(1px -1px 20px #FFFF33);
  animation: none;
}

@keyframes pulsing {
  0% {
    filter: drop-shadow(1px 1px 0px #005abb) drop-shadow(-1px -1px 0px #005abb) drop-shadow(1px -1px 0px #005abb);
  }
  50% {
    filter: drop-shadow(1px 1px 10px #005abb) drop-shadow(-1px -1px 10px #005abb) drop-shadow(1px -1px 20px #005abb);
  }
  100% {
    filter: drop-shadow(1px 1px 0px #005abb) drop-shadow(-1px -1px 0px #005abb) drop-shadow(1px -1px 0px #005abb);
  }
}

.marker-event-pulsing-progress {
  animation: pulsing 2s ease-out infinite;
}

path.stroke-polyline {
  filter: drop-shadow(1px 1px 4px #FFFF33) drop-shadow(-1px -1px 4px #FFFF33) drop-shadow(1px -1px 4px #FFFF33);
}

.MuiFab-extended.MuiFab-sizeMedium {
  height: 48px !important;
  background-color: #005abb !important;
  transition: transform 0.4s ease;
  transform-origin: right;
  border-radius: 50px !important;
  padding-right: 12px !important;
}

.MuiFab-extended.MuiFab-sizeMedium:hover {
  background-color: #005abb !important;
  transform: scaleX(1.02);
}

.MuiPickersToolbar-toolbar,
.MuiTabs-flexContainer,
.MuiPickersDay-daySelected,
.MuiPickersClock-pin,
.MuiPickersClockPointer-pointer,
.MuiPickersClockNumber-clockNumberSelected {
  background-color: #005abb !important;
}

.MuiPickersDay-current {
  color: #005abb;
}

.MuiPickersClockPointer-thumb {
  border-color: #005abb !important;
}

.MuiPaginationItem-textPrimary.Mui-selected {
  background-color: #005abb !important;
}

.cluster-icon-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.cluster-icon-container-full {
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: white;
  padding: 5px;
}

.cluster-icon {
  width: 100%;
  height: 100%;
}

.cluster-count {
  position: absolute;
  bottom: -20px;
  left: 25%;
  transform: translateX(-50%);
  background: white;
  border: 1px solid #000;
  border-radius: 50%;
  min-width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 12px;
  white-space: nowrap;
}

.cluster-count[data-digits="2"] {
  font-size: 11px;
}

.cluster-count[data-digits="3"] {
  font-size: 10px;
}
/*# sourceMappingURL=index.d3d90afa.css.map */
